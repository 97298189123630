import type { FC } from 'react'

import type { BenefitsWithImageType } from '@Types/blocks/benefitsWithImage'
import type { ExtendBlocksType } from '@Types/blocks/blocks'

import Card from './components/Card'
import { BenefitsWithImagesContainer, ScrollWrapper } from './styles'

type Props = {
  data: ExtendBlocksType
}

export const BenefitsWithImages: FC<Props> = ({ data }) => {
  const { benefits } = data as BenefitsWithImageType

  return (
    <ScrollWrapper data-block={data.type}>
      <BenefitsWithImagesContainer>
        {benefits.map(item => (
          <Card itemId={item} key={item} />
        ))}
      </BenefitsWithImagesContainer>
    </ScrollWrapper>
  )
}
