import BodyText from 'firebird-ui/src/components/BodyText'
import Image from 'next/image'
import styled from 'styled-components'

import ArrowUpRightIcon from '@iconscout/unicons/svg/line/arrow-up-right.svg'

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-inline: 80px;
  row-gap: 16px;
  z-index: 2;

  &::before {
    position: absolute;
    left: 0;
    content: '';
    height: 100%;
    width: 8px;
    background: ${p => p.theme.colors.yellow10};
  }

  ${p => p.theme.mediaQueries.mobileTablet} {
    padding-inline: 40px 24px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    padding-inline: 24px;
  }
`

export const Title = styled.span`
  ${p => p.theme.typography.desktop.heading.small.$value};
  font-weight: 500;
  color: ${p => p.theme.colors.white};
`

export const Text = styled(BodyText)`
  color: ${p => p.theme.colors.white};
`

export const Icon = styled(ArrowUpRightIcon)<{ $iconUrl?: false | string }>`
  fill: ${p => (p.$iconUrl ? 'transparent' : p.theme.colors.white)};
  z-index: 1;
  position: absolute;
  top: 32px;
  inset-inline-end: 32px;
  padding: 8px;
  height: 40px;
  border-radius: ${p => (p.$iconUrl ? 0 : 50)}%;
  background: ${p => (p.$iconUrl ? `center / cover no-repeat url('${p.$iconUrl}')` : p.theme.colors.yellow10)};

  ${p => p.theme.mediaQueries.mobileTablet} {
    top: 24px;
    inset-inline-end: 24px;
  }
`

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-block: 56px;
  border-radius: ${p => p.theme.borderRadius.sm};
  align-items: end;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    background: linear-gradient(to bottom, ${p => p.theme.colors.black0}, ${p => p.theme.colors.black100});
  }

  &:not(:first-child) {
    padding-block: 24px;
    align-items: start;

    &::after {
      background: linear-gradient(to top, ${p => p.theme.colors.black0}, ${p => p.theme.colors.black100});
    }

    ${Content} {
      padding-inline: 32px;

      &::before {
        content: none;
      }

      ${p => p.theme.mediaQueries.mobileTablet} {
        padding-inline-start: 32px;
      }

      ${p => p.theme.mediaQueries.mobile} {
        padding-inline-start: 24px;
      }
    }

    ${Icon} {
      top: auto;
      bottom: 32px;

      ${p => p.theme.mediaQueries.mobileTablet} {
        bottom: 24px;
      }
    }
  }

  ${p => p.theme.mediaQueries.mobileTablet} {
    padding-block: 24px;
  }
`
export const BackgroundImage = styled(Image)`
  object-fit: cover;
  border-radius: inherit;
`
